import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import portfolioImage from "../images/portfoliologo.svg"
import worldImage from "../images/worldlogo.svg"
import transistorImage from "../images/transistorlogo.svg"

const IndexPage = () => (
  <Layout landing={ true }>
    <section className="content__body--projects">
        <Link to="/portfolio/">
            <img src={ portfolioImage } alt="Portfolio Logo"/>
            <span>Portfolio</span>
        </Link>

         <a href="https://histor-ia.com/">
             <img src="https://histor-ia.com/images/huella.svg" alt="histor.ia Logo"/>
             <span>histor.ia</span>
         </a>

        <Link to="/travel/">
            <img src={ worldImage } alt="World Logo"/>
            <span>The world from an engineer's point of view</span>
        </Link>

        <Link to="/coming/">
            <img src={ transistorImage } alt="Transistor Logo"/>
            <span>Working stories</span>
        </Link>
    </section>
  </Layout>
)

export default IndexPage
